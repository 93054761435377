body {
    background-color: #f6f7fc;
}

.route-wrapper {
    width: 100%;
    position: relative;
}

.route-wrapper > div {
    width: 100%;
    position: absolute;
}
